const configOverride = (window as any)?.configOverride ?? {
  featBitConfig: {
    sdkKey: '8EtV3kTKi0SrUxoHHupGWAzw0l-3ce6kGJGgyt3tp-_A',
    streamingUri: 'wss://featbit-evaluation.qa.medad.com',
    eventsUrl: 'https://featbit-evaluation.qa.medad.com',
    pollingUri: 'https://featbit-evaluation.qa.medad.com',
  },
};
const config = configOverride.featBitConfig;

export const featBitOptions = {user: {keyId: 'tester-id'}, ...config};
