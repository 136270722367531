import {useFonts} from 'expo-font';

export function useAppFonts() {
  return useFonts({
    Tajawal: require('../../assets/fonts/Tajawal-Regular.ttf'),
    'Tajawal-Medium': require('../../assets/fonts/Tajawal-Medium.ttf'),
    'Tajawal-SemiBold': require('../../assets/fonts/Tajawal-SemiBold.ttf'),
    'Tajawal-Bold': require('../../assets/fonts/Tajawal-Bold.ttf'),
  });
}
