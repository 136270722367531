import React from 'react';

import {ScrollView, Text, View, XStack} from '@medad-sep/core';
import {PagesItems} from '@medad-sep/shell-default/components/PagesList';
import {PageItem} from '@medad-sep/shell-default/components/PagesList/PageItem';
import {Language} from '@medad-sep/shell-default/screens/Settings/sections/Language';
import {AuthAction} from '@medad-sep/shell-default/screens/Settings/sections/SettingsMain/components/AuthAction';

export const Main = ({pageItems}: {pageItems: PagesItems}) => {
  return (
    <ScrollView>
      <View flex={1} justifyContent="center" flexDirection="row" flexWrap="wrap">
        <XStack width="100%" padding="$1" justifyContent="space-between" alignItems="center">
          <Text color="$brand-600" fontWeight="bold" fontSize={24}>
            Playground
          </Text>

          <XStack space="$1">
            {Language.Icon}
            <AuthAction />
          </XStack>
        </XStack>

        {pageItems.map((page, i) => (
          <PageItem key={page.id} id={page.id} icon={page.icon} title={page.title} index={i} />
        ))}
      </View>
    </ScrollView>
  );
};
